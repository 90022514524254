// 项目配置文件

/**
 *  当前环境变量，并非模式
 *  反向代理      proxy
 *  开发环境      development    npm run serve   or npm run dev   or  npm run development
 *  生产环境      production     npm run build
 */
let env = process.env.VUE_APP_TITLE || process.env.NODE_ENV
    // env = 'proxy'

console.log(process.env)

// 项目服务器地址配置
let server = {
    proxy: '/api',
    development: 'https://livechat.kr65.com',
    test: 'https://livechat.kr65.com',
    production: 'https://livechat.kr65.com',
}

export default {
    env,
    url: server[env],
}