import Vue from 'vue'
import Vuex from 'vuex'
import emoji from '@/utils/emoji'
Vue.use(Vuex)

const store = () =>
    new Vuex.Store({
        state: {
            emojiData: emoji,
            activeLang: "", //当前的语言
            langObj: { //语言列表
                "cn": "中文",
                "en": "English"
            },
            errorOpt:{
                action:"errors",
                error_message:"",
                request_url:"",
                stack:""
            },
            errorTimer:null,   //上报异常的定时器
        },
        mutations: {},
        actions: {},
        getters: {}
    });

export default store