/**
 * 封装Axios
 * 处理请求、响应错误信息
 */
import { Message } from 'element-ui' //引用饿了么UI消息组件
import $axios from 'axios' //引用axios
import { getStore,reportErrorFunc } from '@/utils/utils'
import { Base64 } from 'js-base64'
const sendRequest = (
    url, { data = {}, method = 'POST', load = 0, type = 0, noToken = false, ...params } = {}
) => {
    return new Promise((resolve, reject) => {
        // create an axios instance
        var headers = { 'Content-Type': 'application/json' }
        if (getStore('token') && !noToken)
            headers['Authorization'] = 'Bearer ' + Base64.encode(`${getStore('visitorAccid')}:${getStore('token')}`)
        let service = $axios.create({
            baseURL: url,
            method: method,
            // type: type,
            timeout: 0, // 无超时时间
            withCredentials: true, // 表示跨域请求时是否需要使用凭证  send cookies when cross-domain requests
            headers: headers,
        })

        // request interceptor
        service.interceptors.request.use(
            (config) => {
                // do something before request is sent
                // config.headers['-Token'] = getToken()
                // 页面查询字段为空可不传参数,页面编辑字段都要传值
                let argument = {}
                if (config.type === 0) {
                    // 去除参数中的'' 、nul 、  undefined  表单提交的不较验
                    if (typeof data === 'string') {
                        argument = data
                    } else {
                        for (let item in data) {
                            if (
                                data[item] === '' ||
                                data[item] === null ||
                                typeof data[item] === 'undefined' ||
                                (typeof data[item] === 'object' &&
                                    data[item].length === 0)
                            )
                                continue
                            argument[item] = data[item]
                        }
                    }
                    config.method === 'post' ? (config.data = argument) : (config.params = argument)
                } else {
                    //  去除参数中的null undefined
                    for (let item in data) {
                        if (!data[item] && data[item] !== 0) data[item] = ''
                    }
                    argument = data;
                    config.method === 'post' ? (config.data = argument) : (config.params = argument)
                }
                return config
            },
            (error) => {
                // do something with request error
                console.log(error) // for debug
                return Promise.reject(error)
            }
        )

        // response interceptor
        service.interceptors.response.use(
            (response) => {
                // response.setHeader("X-Frame-Options", "SAMEORIGIN");
                if (response.data.code != 200) {
                    response.data.errmsg&&Message({
                        message: response.data.errmsg,
                        type: 'warning',
                        duration: 3000,
                    })
                }
                return response.data
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        service()
            .then((response) => {
                response && resolve(response)
            })
            .catch((error) => {
                console.log('请求异常信息：' + error)
                reportErrorFunc({action: params.name||"ajax",request_url:url,error_message:""+ error})
                // Message({
                //     message: '服务器繁忙',
                //     type: 'warning',
                //     duration: 3000,
                // })
                reject(error)
            })
    })
}

export default sendRequest //导出封装后的axios