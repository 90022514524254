import sendRequest from './request'
import api from './url'
import Qs from 'qs'

// 1、渠道页设置信息
export const setting = (params) => {
    return sendRequest(
        api.url + `/chat/webimplugin/visitors/settings/configs/${params.configId}`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"setting"
        }
    )
}

// 2、目标渠道接口，主要是获取ws服务器连接信息（租户应用渠道分配的线路）
export const targetChannels = (params) => {
    return sendRequest(
        api.url + `/chat/webimplugin/targetChannels/${params.tenantId}`, {
            data: {},
            method: 'GET',
            load: 0,
            noToken: true,
            name:"targetChannels"
        }
    )
}

// 3、访客获取登录密码，只有过期才会重新生成 获取cookie的key为gv_visitor_accid
export const getPsw = (params) => {
        return sendRequest(
            api.url +
            `/chat/webimplugin/visitors/password/${params.visitorAccid ||
        'v@4d4ab185-0a40-4844-ab06-adf2f0e099ae'}`, {
                data: params.data,
                method: 'GET',
                load: 0,
                noToken: true,
                name:"getPsw"
            }
        )
    }
    // 4、访客登录并获取token  params.data:{"grant_type":"password","account":"v@4d4ab185-0a40-4844-ab06-adf2f0e099ae","password":"123456"}
export const getToken = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/visitor/token/${params.tenantId}/${params.appName}`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: true,
            name:"getToken"
        }
    )
}

// 4、网页访客端-注册(生成)访客帐号
export const generateUser = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/visitor/tenants/${params.tenantId}/generateUser`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"generateUser"            
        }
    )
}


// 5、更多历史消息  sessionId是最旧的那条消息的id data:{"from_seqid":"0","size":"10"}
export const getHistory = (params) => {
    return sendRequest(
        api.url +
        `/chat/tenant/${params.tenantId}/${params.agentAccid}/conversation/${params.sessionId}/history/messages`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"getHistory"                        
        }
    )
}

// 6、开启会话  只有开启会话,才能开始WebSocket建立连接
export const lastSession = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/visitors/${params.visitorAccid}/latest-session`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"lastSession"                        
        }
    )
}

// 7、访客发送数据
export const sendMsg = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/visitors/me/serviceSession/${params.sessionId}/${params.toAccid}/messages`, {
            data: params.data,
            method: 'POST',
            load: 0,
            csrf: true,
            noToken: false,
            name:"sendMsg"            
        }
    )
}
// 8、客服信息
export const getAgentMsg = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/agents/${params.agentAccid}/info`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"getAgentMsg"            
        }
    )
}
// 8、客服在线状态 (轮询,每30一次请求)
export const getAgentstate = (params) => {
    return sendRequest(
        api.url +
        `/chat/tenants/${params.tenantId}/agents/${params.agentAccid}/agentstate`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"getAgentstate"
        }
    )
}

// 9、设置消息为已读
export const setMessageRead = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/visitor/tenants/${params.tenantId}/setMessageRead`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: true,
            name:"setMessageRead"
        }
    )
}

// 10、提价评价
export const addEvaluation = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/visitor/evaluation/add`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"addEvaluation"
        }
    )
}

// 11、留言
export const setTickets = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/leave/word/tickets`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"setTickets"
        }
    )
}

// 12、未读消息数
export const getUnReadNum = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/visitors/${params.visitorAccid}/unread/messages`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"getUnReadNum"
        }
    )
}

// 13、访客正在输入内容中
export const msgPredict = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/servicesessions/${params.sessionId}/visitors/${params.visitorAccid}/message-predict`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"msgPredict"
        }
    )
}

// 15、记录访客环境信息
export const attributes = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/tenants/${params.tenantId}/sessions/${params.sessionId}/attributes`, {
            data: params.data,
            method: 'POST',
            load: 0,
            noToken: false,
            name:"attributes"
        }
    )
}

// 16、企业欢迎词
export const getWelcome = (params) => {
    return sendRequest(api.url + `/chat/webimplugin/welcome/${params.tenantId}`, {
        data: params.data,
        method: 'GET',
        load: 0,
        noToken: true,
        name:"getWelcome"
    })
}

// 17、常见问题
export const getQuestion = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/settings/tenants/${params.tenantId}/${params.configId}/information/issues`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"getQuestion"
        }
    )
}

// 18、获取自助服务
export const getSelfService = (params) => {
    return sendRequest(
        api.url +
        `/chat/webimplugin/settings/tenants/${params.tenantId}/${params.configId}/information/selfService`, {
            data: params.data,
            method: 'GET',
            load: 0,
            noToken: true,
            name:"getSelfService"
        }
    )
}

// 19 上报异常
export const reportError = (params) => {
    return sendRequest(
        api.url + `/report`, {
            data: params,
            method: 'POST',
            load: 0,
            noToken: true,
        }
    )
}
