import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Viewer from 'v-viewer'
import VueI18n from "vue-i18n"
import { getStore,reportErrorFunc } from "./utils/utils"
import { Base64 } from 'js-base64'
require('@/utils/bundle?v=2.0')
require('@/utils/math.uuid.js')
import { Input, Message, Loading, Upload,MessageBox } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/basic.scss'
import "./assets/style/element-variable.scss";
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, { name: 'viewer' })
    .use(Input)
    .use(Loading.directive)
    .use(Upload)
    .use(VueI18n)
Vue.component(Message)
Vue.component(MessageBox)
Vue.prototype.$Base64 = Base64
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$loading = Loading.service
//系统错误捕获
Vue.config.errorHandler = (error, vm)=>{
    console.error('抛出全局异常');
    console.error(vm);
    console.error(error);
    reportErrorFunc({action: "js模块逻辑错误",request_url:"",error_message:""+ error})
};
Vue.config.productionTip = false
const i18n = new VueI18n({

    locale: getStore('user_lang') == null ? "cn" : getStore('user_lang'),
    messages: {
        'cn': require('./utils/lang/cn'), // 中文语言包
        'en': require('./utils/lang/en') // 英文语言包
    },
})


const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
})
app.$mount('#app')

export default app