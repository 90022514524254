import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        // 首页
        {
            path: '/',
            name: 'index',
            redirect: '/home',
            component: (resolve) => require(['@/views/index'], resolve),
            children: [{
                path: '/home',
                name: 'home',
                component: (resolve) => require(['@/views/pages/home'], resolve),
            },{
              path: '/login',
              name: 'login',
              component: (resolve) => require(['@/views/pages/login'], resolve),
          }, ],
        }
    ],
})

export default router