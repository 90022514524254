// const files = require.context("../assets/images/bqblist", true, /\.gif$/);
// var path = require('path');
// const expressions = {}
// for (const key of files.keys()) {
//     let keys = `[ee_${path.basename(key).replace(".gif", "")}]`;
//     expressions[keys] = files(key)
// }

var imageHost = 'https://livechat.kr65.com'
var imageHost2 = 'https://www.kr65.com'
let expressions = {
    '[ee_1]': imageHost + '/resources/images/webim/emoji/ee_1.png',
    '[ee_2]': imageHost + '/resources/images/webim/emoji/ee_2.png',
    '[ee_3]': imageHost + '/resources/images/webim/emoji/ee_3.png',
    '[ee_4]': imageHost + '/resources/images/webim/emoji/ee_4.png',
    '[ee_5]': imageHost + '/resources/images/webim/emoji/ee_5.png',
    '[ee_6]': imageHost + '/resources/images/webim/emoji/ee_6.png',
    '[ee_7]': imageHost + '/resources/images/webim/emoji/ee_7.png',
    '[ee_8]': imageHost + '/resources/images/webim/emoji/ee_8.png',
    '[ee_9]': imageHost + '/resources/images/webim/emoji/ee_9.png',
    '[ee_10]': imageHost + '/resources/images/webim/emoji/ee_10.png',
    '[ee_11]': imageHost + '/resources/images/webim/emoji/ee_11.png',
    '[ee_12]': imageHost + '/resources/images/webim/emoji/ee_12.png',
    '[ee_13]': imageHost + '/resources/images/webim/emoji/ee_13.png',
    '[ee_14]': imageHost + '/resources/images/webim/emoji/ee_14.png',
    '[ee_15]': imageHost + '/resources/images/webim/emoji/ee_15.png',
    '[ee_16]': imageHost + '/resources/images/webim/emoji/ee_16.png',
    '[ee_17]': imageHost + '/resources/images/webim/emoji/ee_17.png',
    '[ee_18]': imageHost + '/resources/images/webim/emoji/ee_18.png',
    '[ee_19]': imageHost + '/resources/images/webim/emoji/ee_19.png',
    '[ee_20]': imageHost + '/resources/images/webim/emoji/ee_20.png',
    '[ee_21]': imageHost + '/resources/images/webim/emoji/ee_21.png',
    '[ee_22]': imageHost + '/resources/images/webim/emoji/ee_22.png',
    '[ee_23]': imageHost + '/resources/images/webim/emoji/ee_23.png',
    '[ee_24]': imageHost + '/resources/images/webim/emoji/ee_24.png',
    '[ee_25]': imageHost + '/resources/images/webim/emoji/ee_25.png',
    '[ee_26]': imageHost + '/resources/images/webim/emoji/ee_26.png',
    '[ee_27]': imageHost + '/resources/images/webim/emoji/ee_27.png',
    '[ee_28]': imageHost + '/resources/images/webim/emoji/ee_28.png',
    '[ee_29]': imageHost + '/resources/images/webim/emoji/ee_29.png',
    '[ee_30]': imageHost + '/resources/images/webim/emoji/ee_30.png',
    '[ee_31]': imageHost + '/resources/images/webim/emoji/ee_31.png',
    '[ee_32]': imageHost + '/resources/images/webim/emoji/ee_32.png',
    '[ee_33]': imageHost + '/resources/images/webim/emoji/ee_33.png',
    '[ee_34]': imageHost + '/resources/images/webim/emoji/ee_34.png',
}
let expressions2 = {
    '[ee_50]': imageHost + '/resources/images/webim/emoji2/160a.png',
    '[ee_51]': imageHost+ '/resources/images/webim/emoji2/160b.png',
    '[ee_52]': imageHost + '/resources/images/webim/emoji2/160c.png',
    '[ee_53]': imageHost + '/resources/images/webim/emoji2/160d.png',
    '[ee_54]': imageHost + '/resources/images/webim/emoji2/160f.png',
    '[ee_55]': imageHost + '/resources/images/webim/emoji2/161a.png',
    '[ee_56]': imageHost + '/resources/images/webim/emoji2/161e.png',
    '[ee_57]': imageHost + '/resources/images/webim/emoji2/606.png',
    '[ee_58]': imageHost + '/resources/images/webim/emoji2/1601.png',
    '[ee_59]': imageHost + '/resources/images/webim/emoji2/1602.png',
    '[ee_60]': imageHost + '/resources/images/webim/emoji2/1603.png',
    '[ee_61]': imageHost + '/resources/images/webim/emoji2/1604.png',
    '[ee_62]': imageHost + '/resources/images/webim/emoji2/1605.png',
    '[ee_63]': imageHost + '/resources/images/webim/emoji2/1609.png',
    '[ee_64]': imageHost + '/resources/images/webim/emoji2/1612.png',
    '[ee_65]': imageHost + '/resources/images/webim/emoji2/1614.png',
    '[ee_66]': imageHost + '/resources/images/webim/emoji2/1616.png',
    '[ee_67]': imageHost + '/resources/images/webim/emoji2/1618.png',
}
var emoji = {
    emoji1:expressions,
    emoji2:expressions2,
}
export default emoji