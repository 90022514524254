<template>
  <div id="app" >
    <router-view />
  </div>
</template>
<script>
import {getStore} from "./utils/utils";
const version = require('element-ui/package.json').version
const ORIGINAL_THEME = '#ff9902';
export default {
  name:"app",
  created(){
    this.$store.state.activeLang = getStore("user_lang")?this.$store.state.langObj[getStore("user_lang")]:"中文"
  },

}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
