import Cookies from "js-cookie";
import vm from "../main.js";
import {reportError} from "../fetch/api"
const app = "j_p_x_talk_";
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== "string") {
        content = JSON.stringify(content)
    }
    window.localStorage.setItem(`${app}${name}`, content);
}

/**
 * 获取localStorage
 */
export const getStore = (name) => {
    if (!name) return;
    let item = window.localStorage.getItem(`${app}${name}`);
    try {
        item = JSON.parse(item);
    } catch (e) {}
    return item;
}

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
    if (!name) return;
    window.localStorage.removeItem(`${app}${name}`);
}

/**
 * 存储Cookies
 */

export const setCookies = (name, content) => {
    if (!name) return;
    if (typeof content !== "string") {
        content = JSON.stringify(content);
    }
    Cookies.set(`${app}${name}`, content);
}

/**
 * 获取Cookies
 */
export const getCookies = name => {
    if (!name) return;
    let item = Cookies.get(`${app}${name}`);
    try {
        item = JSON.parse(item);
    } catch (e) {}
    return item;
};

/**
 * 删除Cookies
 */
export const removeCookies = name => {
    if (!name) return;
    Cookies.remove(`${app}${name}`);
};

export const dateFormat = (fmt, date) => {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "m+": (date.getMonth() + 1).toString(), // 月
      "d+": date.getDate().toString(), // 日
      "H+": date.getHours().toString(), // 时
      "M+": date.getMinutes().toString(), // 分
      "S+": date.getSeconds().toString() // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

export const reportErrorFunc = (params) => {
    if(vm.$store.state.errorTimer) clearTimeout(vm.$store.state.errorTimer);
    console.error("===================",params)
    vm.$store.state.errorTimer = setTimeout(()=>{
        vm.$store.state.errorOpt = {...vm.$store.state.errorOpt,...params}
        reportError(vm.$store.state.errorOpt).then(res=>{})
        vm.$store.state.errorTimer = null;
    },3000)
}